import React, { useState, useEffect} from 'react'
import Recaptcha from "react-recaptcha"
import PrimaryButton from '../../components/Buttons/PrimaryButton'
import axios from 'axios'
import { Helmet } from 'react-helmet'

const recaptchaSiteKey = '6LcxeJIdAAAAACwLCaT0W_7J73P6aWCOMW75JYCk'

const inputGroupClasses = "w-full mx-auto mb-6 md:mb-9"
const labelClasses = "label block text-xs md:text-lg font-bold mb-2 md:mb-0.5 md:leading-7"
const helptextClasses = "block text-xs md:text-sm mt-1 mb-0 text-left md:leading-7"
const inputClasses = "w-full bg-white border-black border-2 h-14 md:h-20 p-6 rounded-none"
const submmtStyles = "primary-btn block md:table h-12 md:h-14 rounded-full text-base leading-5 px-8 py-3 md:py-4 font-bold text-center border-2 border-black text-white bg-black"

const Form = ({ 
    name,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    message,
    setMessage,
    result,
    children,
    onSubmit,
    error,
    invalidFields,
    showInvalidFields,
}) => {

    const textAreaLimit = 500
    
    return (
      <>
        { !result || result.status !== 200
        ? (
          <div className={`text-black`}>
            <div style={{ maxWidth: 1600 }} className="z-10 relative block md:grid md:grid-cols-2 md:gap-16 lg:gap-32 w-full pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-24 mx-auto text-left">
              <div className={`w-full mx-auto mb-10 md:mb-0 `}>
                <h1 className={`h2 mb-8`}>Get in touch.</h1>
                <p className={`p`}>Do you have a project you think we would be a fit for? Looking for some unique marketing initiatives, influencer programs or PR stunts?<br/><br/>Send us a note – we’d love to talk.</p>
              </div>
              <form className={`w-full mx-auto mb-10 md:mb-0`}>
                <div className="grid grid-cols-2 gap-2 md:gap-3">
                  <div className={`${inputGroupClasses} ${showInvalidFields && invalidFields.includes("First Name") ? 'error' : ''}`}>
                    <legend className={labelClasses}>Name*</legend>
                    <input className={inputClasses} type="text" id="first_name" placeholder="" maxLength={70} onChange={(e) => setFirstName(e.target.value)} value={firstName} required />
                    <label className={helptextClasses} htmlFor="first_name">First name</label>
                  </div>
                  <div className={`${inputGroupClasses} ${showInvalidFields && invalidFields.includes("Last Name") ? 'error' : ''}`}>
                    <div className={labelClasses}>&nbsp;</div>
                    <input className={inputClasses} type="text" id="last_name" placeholder="" maxLength={70} onChange={(e) => setLastName(e.target.value)} value={lastName} required />
                    <label className={helptextClasses} htmlFor="last_name">Last name</label>
                  </div>
                  <input id="name" defaultValue={name} hidden />
                  <input id="subject" defaultValue={'Blokhaus - Contact Form'} hidden />
                </div>
                <div className="w-full">
                  <div className={`${inputGroupClasses} ${showInvalidFields && invalidFields.includes("Email") ? 'error' : ''}`}>
                    <label className={labelClasses} htmlFor="email">Email*</label>
                    <input className={inputClasses} type="email" id="email" placeholder="" maxLength={70} onChange={(e) => setEmail(e.target.value)} value={email} required />
                  </div>
                </div>
                <div className="w-full">
                  <div className={`${inputGroupClasses} ${showInvalidFields && invalidFields.includes("Message") ? 'error' : ''}`}>
                    <label className={labelClasses} htmlFor="message">Message*</label>
                    <textarea className={inputClasses} id="message" placeholder="" 
                      maxLength={textAreaLimit}
                      onChange={(e) => setMessage(e.target.value)}
                      onPaste={(e) => setMessage(e.target.value)}
                      onCut={(e) => setMessage(e.target.value)}
                      onBlur={(e) => setMessage(e.target.value)}
                      value={message}
                      required
                    />
                  </div>
                </div>
                <div className="w-full mb-6 md:mb-9">
                  {children}
                </div>
                <div className="w-full has-error  mb-6 md:mb-9">
                  {error && <p className={`${labelClasses} error`}>An error occurred. Please try again later.</p>}
                  {showInvalidFields && invalidFields.length > 0 && <p className={`${labelClasses} error`}>Please fill out required fields: {invalidFields.join(', ')}.</p>}
                </div>
                <div className="w-full">
                  <div className={inputGroupClasses}>
                    <button className={`mb-9 ${submmtStyles}`}
                        onClick={(e) => onSubmit(e)}
                    >Send</button>
                  </div>
                </div>
            </form>
          </div>
        </div>

        ) : (
          <div style={{ maxWidth: 1600 }} className="main z-10 relative w-full pt-10 pb-6 px-6 sm:px-12 md:px-24 md:pt-24 md:pb-24 mx-auto text-center flex">
            <div className={`w-full mx-auto mb-10 md:mb-0 flex flex-col self-center`}>
              <h1 className={`h2 mb-2.5`}>Thank you!</h1>
              <p className={`p`}>Your submission has been received.<br/><br/></p>
              <PrimaryButton
                text={`Back to Home`}
                url={`/`}
                className={`md:mx-auto`}
              />
            </div>
          </div>
          )}
      </>
    )
}

export default function ContactPage() {

    const [ name, setName ] = useState('')
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ subject, setSubject ] = useState('')
    const [ message, setMessage] = useState('')
    const [ error, setError ] = useState(false)
    const [ invalidFields, setInvalidFields ] = useState(['First Name', 'Last Name', 'Email', 'Message', 'reCAPTCHA'])
    const [ showInvalidFields, setShowInvalidFields ] = useState(false)

    const [ recaptcha, setRecaptcha ] = useState('')
    const [ expired, setExpired ] = useState(false)
    const [ result, setResult ] = useState(null)
    const [ validated, setValidated ] = useState(false)

    let recaptchaInstance

    function setRecaptchaRef(r) {
        if (r) {
            return recaptchaInstance = r;
        }
    }
    const validEmail = /\S+@\S+\.\S+/.test(email)

    function verifyCallback(response) {
        setRecaptcha(response)
        setExpired(false)
    }

    function expiredCallback() {
        setExpired(true)
    }

    function resetRecaptcha() {
        recaptchaInstance.reset()
    }

    function onSubmit(e) {
        e.preventDefault()

        if (!validated) {
            setShowInvalidFields(true)
            return
        }

        showInvalidFields && setShowInvalidFields(false)
        axios.post('https://us-central1-blokhaus-site-form.cloudfunctions.net/contactSubmit', {
            name,
            email,
            subject,
            message,
            recaptcha
        })
        .then((res) => {
            if (res.status !== 200) setError(true)
            else success(res)
        })
        .catch((err) => {
            setError(true)
            resetRecaptcha()
        })
    }

    function success(result) {
        setResult(result)
    }


    useEffect(() => {
        setName(firstName + " " + lastName)
        setSubject(`Blokhaus Site - Contact Form submission from ${name}.`)

        function checkFields() {
            const fields = []
            // TODO update check for both first and last name
            firstName.length === 0 && fields.push('First Name')
            lastName.length === 0 && fields.push('Last Name')
            !validEmail && fields.push('Email')
            message.length === 0 && fields.push('Message')
            recaptcha.length === 0 && fields.push('reCAPTCHA')
            setInvalidFields(fields)

            fields.length === 0 
            ? setValidated(true)
            : setValidated(false)
        }
        
        checkFields()

    }, [ recaptcha, expired, firstName, lastName, message, validEmail, email])

    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blokhaus - Contact</title>
      </Helmet>
          <Form
              name={name}
              setName={setName}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              subject={subject}
              setSubject={setSubject}
              message={message}
              setMessage={setMessage}
              result={result}
              onSubmit={onSubmit}
              error={error}
              invalidFields={invalidFields}
              showInvalidFields={showInvalidFields}
          >
            <div>
                <Recaptcha
                  ref={(r) => setRecaptchaRef(r)}
                  sitekey={recaptchaSiteKey}
                  render="explicit"
                  verifyCallback={verifyCallback}
                  onExpired={expiredCallback}
                  onloadCallback={()=>{}} 
                  theme="light"
              />
            </div>
        </Form>
    </>
    )
}